import { render, staticRenderFns } from "./index.vue?vue&type=template&id=93b5386a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=93b5386a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93b5386a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CkLoader: require('/opt/build/repo/components/Ck/Loader/index.vue').default,CkIcon: require('/opt/build/repo/components/Ck/Icon/index.js').default})
