
export default {
  props: {
    parentIsLoading: Boolean,
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    start() {
      this.isLoading = true
    },
    finish() {
      // Make the loader time longer on init
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
  },
}
