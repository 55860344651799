export const state = () => ({
  country: null,
  language: null,
  tbsId: null,
  reportId: null,
  mode: null,
})

export const mutations = {
  setCountry(state, value) {
    state.country = value
  },
  setLanguage(state, value) {
    state.language = value
  },
  setTbsId(state, value) {
    state.tbsId = value
  },
  setReportId(state, value) {
    state.reportId = value
  },
  setMode(state, value) {
    state.mode = value
  },
}

export const actions = {
  setInitialData({ commit }, { country, language, tbsId, reportId, mode }) {
    commit('setCountry', country)
    commit('setLanguage', language)
    commit('setTbsId', tbsId)
    commit('setReportId', reportId)
    commit('setMode', mode)
  },
}
