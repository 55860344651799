import styled from 'vue-styled-components'

// use <flex-child :grow="'0'" :shrink="'0'" :basis="'0px'"></flex-child>

const componentProps = {
  grow: {
    type: String,
    default: '0',
  },
  shrink: {
    type: String,
    default: '0',
  },
  basis: {
    type: String,
    default: '0px',
  },
}

const FlexChildDiv = styled('div', componentProps)`
  flex: ${(props) => props.grow} ${(props) => props.shrink}
    ${(props) => props.basis};

  .sidebar {
    max-width: ${(props) => props.basis};
  }
`

export default {
  props: {
    grow: String,
    shrink: String,
    basis: String,
  },
  render(h) {
    return (
      <FlexChildDiv
        class="flex-child"
        grow={this.grow}
        shrink={this.shrink}
        basis={this.basis}
      >
        {this.$slots.default}
      </FlexChildDiv>
    )
  },
}
