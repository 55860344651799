export default {
  /* THEME COLORS */
  'neutral-050': '#FAFBFC',
  'neutral-100': '#F9F9F9',
  'neutral-200': '#ebeff3',
  'neutral-300': '#d3dbe2',
  'neutral-400': '#B1BDC9',
  'neutral-500': '#8392A1',
  'neutral-600': '#627281',
  'neutral-700': '#516170',
  'neutral-800': '#2F3C49',
  'neutral-900': '#0D0D0D',
  'green-100': '#D6F8E7',
  'green-300': '#81DAAD',
  'green-500': '#2CBA73',
  'blue-050': '#f1f9ff',
  'blue-100': '#E5F5FF',
  'blue-200': '#B7E4FF',
  'blue-300': '#89D2FF',
  'blue-400': '#4FBBFD',
  'blue-500': '#1BA4F7',
  'blue-600': '#008CE1',
  'blue-700': '#0176BE',
  'pink-100': '#F5D3F5',
  'pink-300': '#E9A7E9',
  'pink-400': '#d775c3',
  'pink-500': '#D26BD2',
  'purple-100': '#E5DEFA',
  'purple-300': '#B5A4E6',
  'purple-500': '#866BD2',
  'red-100': '#FFE5E5',
  'red-300': '#FFA8A8',
  'red-500': '#FF6464',
  'orange-100': '#FFF0D8',
  'orange-300': '#FFD799',
  'orange-500': '#FFB94B',
  'teal-100': '#D2F4F4',
  'teal-300': '#7FD7D6',
  'teal-500': '#2CBAB9',
  white: '#ffffff',
  black: '#000000',

  /* THEME STATES */
  default: '#3D9AE3',
  warning: '#FFB61A',
  error: '#FF583B',

  knownColor(value) {
    if (this[value]) {
      return this[value]
    }
    return value
  },
  convertHex(hex, opacity) {
    const hexValue = hex.replace('#', '')
    const r = parseInt(hexValue.substring(0, 2), 16)
    const g = parseInt(hexValue.substring(2, 4), 16)
    const b = parseInt(hexValue.substring(4, 6), 16)

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')'
  },
  knownColorWithOpacity(value, opacity) {
    if (this[value]) {
      const hexValue = this.colorcolor(this[value], 'hex')
      return this.convertHex(hexValue, opacity)
    }
    const hexRawValue = this.colorcolor(value, 'hex')
    return this.convertHex(hexRawValue, opacity)
  },
  colorcolor(color, newColor, calculateOpacity) {
    if (!newColor) {
      newColor = 'rgba'
    }
    if (!calculateOpacity) {
      calculateOpacity = false
    }
    color = color.toLowerCase()
    newColor = newColor.toLowerCase()
    let returnedColor = color
    let r, g, b, a
    const roundTo = 4
    const colorDefinitions = {
      hex: {
        re: /^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
        example: ['00ff00', '336699'],
        toRGBA(bits) {
          return [
            parseInt(bits[1], 16),
            parseInt(bits[2], 16),
            parseInt(bits[3], 16),
            1,
          ]
        },
      },
    }

    // Search the color definitions for a match
    for (const colorDefinition in colorDefinitions) {
      const re = colorDefinitions[colorDefinition].re
      const processor = colorDefinitions[colorDefinition].toRGBA
      const bits = re.exec(color)
      if (bits) {
        const channels = processor(bits)
        r = channels[0]
        g = channels[1]
        b = channels[2]
        a = +(Math.round(channels[3] + ('e+' + roundTo)) + ('e-' + roundTo))
      }
    }
    r = Math.round(r < 0 || isNaN(r) ? 0 : r > 255 ? 255 : r)
    g = Math.round(g < 0 || isNaN(g) ? 0 : g > 255 ? 255 : g)
    b = Math.round(b < 0 || isNaN(b) ? 0 : b > 255 ? 255 : b)
    a = a < 0 || isNaN(a) ? 0 : a > 1 ? 1 : a

    switch (newColor) {
      case 'hex':
        returnedColor =
          '#' +
          ('0' + r.toString(16)).slice(-2) +
          ('0' + g.toString(16)).slice(-2) +
          ('0' + b.toString(16)).slice(-2)
        break
      default:
        if (calculateOpacity) {
          ;[r, g, b, a] = this.calculateOpacityFromWhite(r, g, b, a)
        }
        returnedColor = `rgba(${r},${g},${b},${a})`
        break
    }

    return returnedColor
  },
  calculateOpacityFromWhite(r, g, b, a) {
    let min = 0
    a = (255 - (min = Math.min(r, g, b))) / 255
    r = (0 || (r - min) / a).toFixed(0)
    g = (0 || (g - min) / a).toFixed(0)
    b = (0 || (b - min) / a).toFixed(0)
    a = parseFloat(a.toFixed(4))

    return [r, g, b, a]
  },
  toPercent(amount, limit) {
    return amount / limit
  },
}
