
// See https://element.eleme.io/#/en-US/component/skeleton
// variant:	p / h1 / h3 / text / caption / button / image / circle / rect
import colors from '@/assets/js/theme'
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      skeletonColor: colors.knownColor('neutral-300'),
      skeletonHighlight: colors.knownColor('neutral-200'),
      customStyle: `background-color: ${this.skeletonColor}; background-image: linear-gradient(90deg, ${this.skeletonColor}, ${this.skeletonHighlight}, ${this.skeletonColor}); animation: 1.5s ease-in-out 0s infinite normal none running SkeletonLoading;`,
    }
  },
}
