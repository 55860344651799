export function mergeDeepObjects(deleteNullObjects, target, ...sources) {
  function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item)
  }
  if (!sources.length) return target
  const source = sources.shift()
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (
        (source[key] === null || source[key] === undefined) &&
        deleteNullObjects
      ) {
        // delete null props
        delete source[key]
        if (target[key]) {
          delete target[key]
        }
        continue
      }
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} })
        } else {
          target[key] = Object.assign({}, target[key])
        }
        mergeDeepObjects(deleteNullObjects, target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }
  return mergeDeepObjects(deleteNullObjects, target, ...sources)
}

export function replaceEmpty(value, defaultValue) {
  if (!defaultValue) return value
  if (!value || value === '') {
    return defaultValue
  }
  return value
}
