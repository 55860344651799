import { render, staticRenderFns } from "./InitialPageLoader.vue?vue&type=template&id=408f83a1&scoped=true&"
import script from "./InitialPageLoader.vue?vue&type=script&lang=js&"
export * from "./InitialPageLoader.vue?vue&type=script&lang=js&"
import style0 from "./InitialPageLoader.vue?vue&type=style&index=0&id=408f83a1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408f83a1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Flex: require('/opt/build/repo/components/styled-components/Flex.js').default,FlexChild: require('/opt/build/repo/components/styled-components/FlexChild.js').default,CkSkeletonLoader: require('/opt/build/repo/components/Ck/SkeletonLoader/index.vue').default})
