const opposites = {
  top: 'bottom',
  left: 'right',
  right: 'left',
  bottom: 'top',
}

export default {
  'space-xxs': '5',
  'space-xs': '10',
  'space-s': '15',
  'space-m': '20',
  'space-l': '30',
  'space-xl': '40',
  'space-xxl': '60',
  'space-100': '4',
  'space-200': '8',
  'space-300': '16',
  'space-350': '20',
  'space-400': '24',
  'space-500': '32',
  'space-600': '40',
  'space-700': '48',
  'space-800': '60',
  'space-850': '80',
  'space-900': '90',
  'space-950': '160',
  'screensize-xs': 375,
  'screensize-sm': 768,
  'screensize-md': 812,
  'screensize-lg': 1124,
  'screensize-xl': 1366,
  'screensize-xxl': 1440,

  knownSize(value) {
    if (this[value]) {
      return this[value]
    }
    return value
  },
  knownSizeProp(side, props) {
    if (props.all) {
      return this.knownSize(props.all)
    } else {
      const sideValue = props[side]
      const oppositeValue = props[opposites[side]]
      if (sideValue || sideValue === '0') {
        return this.knownSize(sideValue)
      } else if (sideValue == null && oppositeValue == null) {
        return this.knownSize('space-s')
      } else {
        return this.knownSize(oppositeValue)
      }
    }
  },
}
