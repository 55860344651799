import StyledSvg from './StyledSvg'
import StyledSvgCustom from './StyledSvgCustom'

// use <ck-icon :name="'ico-route-32px'" :fill="'@green-mid'" :hover="'@green-middark'" :size="'32px'" :width="'32px'" :height="'32px'" />

export default {
  props: {
    fill: String,
    size: String,
    width: String,
    height: String,
    name: {
      type: String,
      default: 'ico-delete-16px',
    },
    important: Boolean
  },
  render() {
    if (this.name.includes('multi')) {
      return (
        <StyledSvgCustom
          class="ico multi"
          size={this.size}
          width={this.width}
          height={this.height}
        >
          {this.$createElement(this.name)}
        </StyledSvgCustom>
      )
    }
    return (
      <StyledSvg
        class="ico"
        fill={this.fill}
        size={this.size}
        width={this.width}
        height={this.height}
        important={this.important}
      >
        {this.$createElement(this.name)}
      </StyledSvg>
    )
  },
}
