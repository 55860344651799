import styled from 'vue-styled-components'
import size from '@/assets/js/size'
import colors from '@/assets/js/theme'

// use <flex></flex>

const componentProps = {
  alignItems: {
    type: String,
    default: 'stretch',
  },
  justifyContent: {
    type: String,
    default: 'flex-start',
  },
  flexDirection: {
    type: String,
    default: 'row',
  },
  flexWrap: {
    type: String,
    default: 'nowrap',
  },
  gap: {
    type: String,
    default: 'space-350',
  },
  paddingLeftRight: String,
  column: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: 'auto',
  },
  height: {
    type: String,
    default: 'auto',
  },
}

const FlexDiv = styled('div', componentProps)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: ${(props) => props.flexWrap};
  gap: ${(props) => size.knownSize(props.gap)}px;
  padding: ${(props) =>
    props.paddingLeftRight
      ? `0 ${size.knownSize(props.paddingLeftRight)}px`
      : ''};

  &.column {
    flex-direction: column;
    align-items: flex-start;
  }

  &.split {
    > * {
      &:not(:last-child) {
        border-right: 1px solid
          ${(props) => colors.knownColorWithOpacity('gray-light', 70)};
      }
    }
  }
`

export default {
  props: {
    alignItems: String,
    justifyContent: String,
    flexDirection: String,
    flexWrap: String,
    paddingLeftRight: String,
    gap: String,
    column: Boolean,
    width: String,
    height: String,
  },
  render(h) {
    return (
      <FlexDiv
        class={this.column ? 'flex column' : 'flex'}
        align-items={this.alignItems}
        justify-content={this.justifyContent}
        flex-direction={this.flexDirection}
        flex-wrap={this.flexWrap}
        padding-left-right={this.paddingLeftRight}
        gap={this.gap}
        column={this.column}
        width={this.width}
        height={this.height}
      >
        {this.$slots.default}
      </FlexDiv>
    )
  },
}
